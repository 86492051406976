.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login h1 {
  text-align: center;
  padding-top: 4rem;
}

.login p {
  padding: .5rem;
  margin-top: 5rem;
}

.login a {
  color: whitesmoke;
}