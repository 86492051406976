.message {
  padding: .5rem;
  background: white;
  margin: .5rem 0;
  max-width: 80%;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: space-between;
}

.message.own {
  margin-left: auto;
  border-radius: 20px 0 0 20px;
  background: rgb(149, 255, 149);
  flex-direction: row-reverse;
}